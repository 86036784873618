@use './variables' as v;

@media (prefers-color-scheme: light) {
	:root {
		background-color: #{v.$light-background} !important;
	}
}
@media (prefers-color-scheme: dark) {
	:root {
		background-color: #{v.$dark-background} !important;
	}
}

:root {
	[data-theme='light'] {
		background-color: #{v.$light-background} !important;
	}
	[data-theme='dark'] {
		background-color: #{v.$dark-background} !important;
	}
	// transition: v.$default-transition;
}

body,
.MuiPaper-root {
	.text-link {
		transition: v.$default-transition;
	}
	transition: v.$default-transition;
}
