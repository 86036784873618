@use '../../variables' as v;

.scroll-down-button {
	opacity: 1;
	visibility: visible;

	@media screen and (max-width: v.$sm) {
		opacity: 0;
		visibility: hidden;
	}

	transition: opacity v.$tr-sec ease-in-out, fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;

	path {
		transition: v.$default-transition;
	}
}

.scroll-down-button-hidden {
	opacity: 0;
	visibility: hidden;
	transition: visibility v.$tr-sec ease-in-out, opacity v.$tr-sec ease-in-out, fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
