@use '../../variables' as v;

.carousel {
	position: relative;
	.embla {
		overflow: hidden;

		.embla__viewport {
			.is-draggable {
				cursor: move;
				cursor: grab;
			}

			.is-dragging {
				cursor: grabbing;
			}

			.embla__container {
				display: flex;

				.embla__slide {
					flex: 0 0 100%;
				}
			}
		}

		overflow-y: auto;
		overflow-x: hidden;
	}

	.carousel-label,
	.carousel-stepper {
		transition: v.$default-transition;
		.carousel-step-button {
			transition: inherit;
		}

		.MuiMobileStepper-dot {
			transition: v.$default-transition;
		}
	}

	.top-stepper-header {
		h2 {
			margin-bottom: 0;
		}
	}

	.carousel-step-button-float {
		position: absolute;
		top: calc(50% - 20px);
		transition: v.$default-transition;
	}

	.carousel-step-button-float-left {
		left: 0;
	}

	.carousel-step-button-float-right {
		right: 0;
	}
}

.carousel-show-float-on-hover {
	.carousel-step-button-float {
		visibility: hidden;
		opacity: 0;
	}

	:hover {
		.carousel-step-button-float {
			visibility: visible;
			opacity: 100;
		}
	}
}
