@use '../../variables' as v;

.circle-logo {
	aspect-ratio: 1 / 1;

	transition: v.$default-transition;
	circle,
	path,
	g,
	polyline,
	rect,
	line,
	ellipse,
	polygon,
	text,
	tspan,
	use,
	image,
	clipPath,
	mask,
	pattern,
	marker,
	defs,
	desc,
	title,
	symbol,
	switch,
	foreignObject {
		transition: inherit;
	}

	[id^='circle-logo-tint-'] {
		transition: opacity v.$tr-sec ease-in-out, fill v.$tr-sec ease-in-out;
	}
}
