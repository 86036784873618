@use '../../variables' as v;

#cv-intro-section {
	a {
		transition: v.$default-transition;
	}
}

#cv-document-section {
	@media screen and (max-width: v.$sm) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.pdf-page {
		$shadow-width: 8px;
		border: 2px solid var(--primary-color);
		box-shadow: $shadow-width $shadow-width var(--primary-color);
		margin-left: -$shadow-width;
	}
}
