@use '../../variables' as v;

.section {
	font-size: 22px;
	line-height: 1.1em;

	margin: 0 !important;
	padding: 30px 70px;

	h1 {
		font-size: 40px;
		margin-top: 0;
		margin-bottom: 26px;
		line-height: 1.3em;
		font-weight: bold;
	}

	h3 {
		margin: 0;
	}

	.MuiListItemText-primary {
		font-size: 20px;
	}

	p {
		margin-top: 0;
		line-height: 1.25;
	}

	@media screen and (max-width: 600px) {
		padding: 30px 10%;
	}

	transition: v.$default-transition;

	// a {
	// 	transition: v.$default-transition;
	// }
}
