@use '../../variables' as v;

#toolbar {
	display: flex;
}
#menu-bar {
	width: 30px !important;
	height: 24px;
}

.theme-switch:focus:not(:focus-visible) {
	outline: none;
	box-shadow: none;
}
.theme-switch:focus {
	outline: none;
	border-radius: 0.25rem;
	box-shadow: 0 0 0 0.2rem var(--theme-switch-shadow-color);
}
