$tr-sec: 0.4s;
$default-transition: all $tr-sec ease-in-out;

$light-background: #fff;
$dark-background: #121212;

$xs: '400px';
$sm: '768px';
$md: '992px';
$lg: '1200px';
$xl: '1424px';

$scr-sizes-min: (
	$xs: '0px',
	$sm: $xs,
	$md: $sm,
	$lg: $md,
	$xl: $lg,
);

$scr-sizes: (
	$xs: '45px',
	$sm: '50px',
	$md: '60px',
	$lg: '70px',
	$xl: '80px',
);

$mui-xs: 0px;
$mui-sm: 700px;
$mui-md: 900px;
$mui-lg: 1200px;
$mui-xl: 1536px;

$mui-scr-sizes: (
	'md': $mui-md,
);
