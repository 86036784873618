@use '../../variables' as v;

.research-section {
	.research-media-wrapper {
		position: relative;
		max-width: 400px;

		img,
		video {
			width: 100%;
			border-radius: 12px;
		}
	}

	// Add transition for the MUI Buttons
	.MuiButtonGroup-root {
		.MuiButton-root,
		.MuiButtonGroup-grouped {
			transition: color v.$tr-sec ease-in-out, font-size 0s ease-in-out, background-color v.$tr-sec ease-in-out;

			&.MuiButtonGroup-grouped {
				border-color: inherit;
				transition: color v.$tr-sec ease-in-out, font-size 0s ease-in-out, background-color v.$tr-sec ease-in-out;
			}
		}
	}

	.research-links-mobile {
		display: none;
		@media (max-width: 899.95px) {
			display: block;
		}
	}

	.research-links-desktop {
		display: block;
		@media (max-width: 899.95px) {
			display: none;
		}
	}
}
