$proj-card-md-scr: 750px;
$proj-card-lg-scr: 1000px;
$proj-card-xl-scr: 1150px;

.project-card {
	li {
		padding: 0.1em 0;
	}
	li:last-child {
		padding: 0;
	}

	max-width: 100%;

	@media screen and (max-width: #{$proj-card-md-scr - 1px}) {
		display: block !important;
		.project-tile-md-scr {
			display: none;
			visibility: hidden;
		}
		.project-tile-lg-scr {
			display: none;
			visibility: hidden;
		}
		.project-tile-xl-scr {
			display: none;
			visibility: hidden;
		}
	}
	@media screen
		and (min-width: #{$proj-card-md-scr})
		and (max-width: #{$proj-card-lg-scr - 1px}) {
		display: block !important;
		.project-tile-sm-scr {
			display: none;
			visibility: hidden;
		}
		.project-tile-lg-scr {
			display: none;
			visibility: hidden;
		}
		.project-tile-xl-scr {
			display: none;
			visibility: hidden;
		}
	}
	@media screen
		and (min-width: #{$proj-card-lg-scr})
		and (max-width: #{$proj-card-xl-scr - 1px}) {
		.project-tile-sm-scr {
			display: none;
			visibility: hidden;
		}
		.project-tile-md-scr {
			display: none;
			visibility: hidden;
		}
		.project-tile-xl-scr {
			display: none;
			visibility: hidden;
		}
		.info-container {
			width: 70%;
		}
	}
	@media screen and (min-width: #{$proj-card-xl-scr}) {
		.project-tile-sm-scr {
			display: none;
			visibility: hidden;
		}
		.project-tile-md-scr {
			display: none;
			visibility: hidden;
		}
		.project-tile-lg-scr {
			display: none;
			visibility: hidden;
		}
		.info-container {
			width: 65%;
		}
	}
}
