@use '../../variables' as v;
@use 'sass:map';

.main-page-intro {
	#ai-text-type-delete-text {
		transition: color v.$tr-sec ease-in-out, font-size 0s ease-in-out;

		span {
			transition: color v.$tr-sec ease-in-out, font-size 0s ease-in-out, background-color v.$tr-sec ease-in-out;
		}
	}

	--main-page-intro-font-size: clamp(45px, 6vw, 80px);
	--main-page-intro-cursor-height: clamp(47px, 8vw, 82px);

	@media screen and (max-width: 620px) {
		height: 14em;
	}
}

.text-section {
	min-height: calc(100vh - 50px);

	@media screen and (max-width: v.$sm) {
		min-height: 0;
	}
	@media screen and (max-width: #{v.$mui-sm - 1px}) {
		.lt-lg,
		.lt-md,
		.ge-lg {
			display: none !important;
		}
	}
	@media screen and (min-width: v.$mui-sm) and (max-width: #{v.$mui-md - 1px}) {
		.lt-sm,
		.lt-lg,
		.ge-lg {
			display: none !important;
		}
	}
	@media screen and (min-width: v.$mui-md) and (max-width: #{v.$mui-lg - 1}) {
		.lt-sm,
		.lt-md,
		.ge-lg {
			display: none !important;
		}
	}
	@media screen and (min-width: v.$mui-lg) {
		.lt-sm,
		.lt-md,
		.lt-lg {
			display: none !important;
		}
	}
}

.top-section {
	@media screen and (max-width: v.$sm) {
		min-height: 0 !important;
		transition: min-height 2s cubic-bezier(0.21, 0.24, 0.28, 0.8), padding v.$tr-sec cubic-bezier(0.21, 0.24, 0.28, 0.8) !important;
		padding: 10% !important;
	}

	transition: min-height 1s cubic-bezier(0.21, 0.24, 0.28, 0.8), padding v.$tr-sec cubic-bezier(0.21, 0.24, 0.28, 0.8);
}

.its-a-me {
	transition: v.$default-transition;
}
