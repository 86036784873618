@use '../../variables' as v;

.project-info-breadcrumbs {
	transition: v.$default-transition;

	#project-info-breadcrumbs-1 {
		transition: inherit;
	}
	#project-info-breadcrumbs-2 {
		transition: v.$default-transition;
	}
}
.project-info {
	@media screen and (max-width: 600px) {
		padding: 30px 5% !important;
	}
}
